<script setup lang="ts">
import { uploadInquiry } from "@/composables/useFirebase";
import { ref } from "vue";
import validator from "validator";
import { Head } from "@unhead/vue/components";
import { Inquiry } from "../types";

const inquiry = ref<Inquiry>({
  firstName: "",
  lastName: "",
  message: "",
  email: "",
  phoneNumber: "",
});

const showMessageError = ref(false);
const showSuccessMessageSent = ref(false);
const messageErrors = ref<string[]>([]);
const showMessageErrors = ref(false);
const sendingInquiry = ref(false);

async function onCaptchaInquirySubmit(token: string) {
  if (sendingInquiry.value) return;

  sendingInquiry.value = true;

  const success = await uploadInquiry(inquiry.value, token);

  if (success) {
    showSuccessMessageSent.value = true;

    const container = document.getElementById("contact");

    if (container) {
      container.scrollIntoView({ behavior: "smooth" });
    }
  } else {
    showMessageError.value = true;
  }

  sendingInquiry.value = false;
}

function toggleCaptcha() {
  showMessageErrors.value = false;
  messageErrors.value = [];

  const errors = validateInquiry(inquiry.value);

  if (errors.length === 0) {
    const grecaptcha = window.grecaptcha;

    if (grecaptcha) {
      grecaptcha.render("grecaptcha-container-msg", {
        sitekey: `${process.env.VUE_APP_RECAPTCHA_KEY}`,
        callback: onCaptchaInquirySubmit,
      });
    } else {
      showMessageError.value = true;
    }
  } else {
    showMessageErrors.value = true;
    messageErrors.value = errors;
  }
}

function preventNonNumeric(event: KeyboardEvent) {
  const allowedCharacters = /[0-9]/;
  if (
    !allowedCharacters.test(event.key) ||
    inquiry.value.phoneNumber.length > 11
  ) {
    event.preventDefault();
  }
}

function formatPhoneNumber() {
  const phoneNumber = inquiry.value.phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  const formattedPhoneNumber = phoneNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "$1-$2-$3"
  ); // Format as XXX-XXX-XXXX
  inquiry.value.phoneNumber = formattedPhoneNumber;
}

function validateInquiry(inquiry: Inquiry) {
  const errors: string[] = [];

  if (validator.isEmpty(inquiry.firstName)) {
    errors.push("First Name is required.");
  } else if (
    !validator.isAlpha(inquiry.firstName, "en-US", { ignore: "-' " })
  ) {
    errors.push(
      "First Name can only contain alphabetic characters, spaces, dashes, and apostrophes."
    );
  }

  if (validator.isEmpty(inquiry.lastName)) {
    errors.push("Last Name is required.");
  } else if (!validator.isAlpha(inquiry.lastName, "en-US", { ignore: "-' " })) {
    errors.push(
      "Last Name can only contain alphabetic characters, spaces, dashes, and apostrophes."
    );
  }

  if (validator.isEmpty(inquiry.email)) {
    errors.push("Email is required.");
  } else if (!validator.isEmail(inquiry.email)) {
    errors.push("Invalid email format.");
  }

  if (validator.isEmpty(inquiry.message)) {
    errors.push("Message is required.");
  } else if (inquiry.message.length > 500) {
    errors.push(
      "Message character limit exceeded. Maximum 500 characters allowed."
    );
  }

  if (
    !validator.isEmpty(inquiry.phoneNumber) &&
    !validator.isMobilePhone(inquiry.phoneNumber, "any", { strictMode: false })
  ) {
    errors.push("Invalid phone number format. Required format: XXX-XXX-XXXX");
  }

  return errors;
}
</script>

<template>
  <Head>
    <title>Contact Us | C Squared Media, LLC</title>
    <meta
      name="description"
      content="Contact us today to book a consultation or inquire about our services."
    />
    <meta property="og:title" content="Contact Us | C Squared Media, LLC" />
    <meta
      property="og:description"
      content="Contact us today to book a consultation or inquire about our services."
    />
    <meta name="twitter:title" content="Contact Us | C Squared Media, LLC" />
    <meta
      name="twitter:description"
      content="Contact us today to book a consultation or inquire about our services."
    />
  </Head>
  <section class="contact-us" id="contact">
    <span class="contact-us-heading">Contact Us</span>
    <span class="contact-us-subheading"
      >Get in touch with us using our convenient contact form. Whether you have
      questions, inquiries, or want to discuss your project, we're here to
      listen and assist you. Reach out today, and let's start the conversation.
    </span>
    <div class="contact-us-form-container">
      <form
        v-if="!showSuccessMessageSent && !showMessageError"
        class="contact-us-form"
        @submit.prevent="toggleCaptcha"
        action="?"
        method="POST"
      >
      <div v-if="sendingInquiry" class="sending-overlay">
        <div class="spinner-border" role="status"></div>
      </div>
        <div class="form-group">
          <label for="firstName">First Name</label>

          <input
            v-model="inquiry.firstName"
            type="text"
            class="form-control"
            id="firstName"
            required
            autocomplete="given-name"
          />
        </div>
        <div class="form-group">
          <label for="lastName">Last Name</label>

          <input
            v-model="inquiry.lastName"
            type="text"
            class="form-control"
            id="lastName"
            required
            autocomplete="family-name"
          />
        </div>
        <div class="form-group">
          <label for="email">Email</label>

          <input
            v-model="inquiry.email"
            type="email"
            class="form-control"
            id="email"
            autocomplete="email"
            required
          />
        </div>
        <div class="form-group">
          <label for="phoneNumber">Phone Number</label>

          <input
            v-model="inquiry.phoneNumber"
            @input="formatPhoneNumber()"
            type="tel"
            class="form-control"
            id="phoneNumber"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            @keypress="preventNonNumeric($event)"
            title="XXX-XXX-XXXX"
            autocomplete="tel-national"
            required
          />
        </div>
        <div class="form-group message-textarea">
          <label for="message">Message </label>
          <textarea
            v-model="inquiry.message"
            class="form-control"
            id="message"
            style="height: 6rem"
            pattern="[a-zA-Z0-9\s',.\-]*"
            maxlength="500"
            rows="5"
            required
          ></textarea>
          <div
            :class="`message-count ${
              inquiry.message.length > 500 ? 'max-limit-hit' : ''
            }`"
          >
            ({{ inquiry.message.length }}/500)
          </div>
        </div>
        <div v-if="showMessageErrors" class="inquiries-errors">
          <span
            v-for="(messageError, errorIndex) in messageErrors"
            :key="errorIndex"
            class="inquiry-error-text"
          >
            {{ messageError }}</span
          >
        </div>
        <div
          class="google-recaptcha-container"
          id="grecaptcha-container-msg"
        ></div>
        <button type="submit" class="btn btn-secondary btn-send">Send</button>
      </form>
      <div
        v-if="showSuccessMessageSent || showMessageError"
        class="form-responses"
      >
        <div v-if="showSuccessMessageSent" class="successful-response">
          <span class="success-header">Thank You!</span>
          <span class="success-description"
            >We look forward to following up with you.</span
          >
        </div>
        <div v-if="showMessageError" class="failed-response">
          <span class="failed-header">Oops! Something went wrong.</span>
          <span class="failed-description"
            >We encountered an issue while processing your consultation request.
            Please contact us directly using the listed contact methods below
            for assistance.</span
          >
        </div>
      </div>
      <section class="contact-us-images">
        <img
          class="contact-founder-img"
          src="@/assets/csquared-founder-4.jpg"
          alt="woman sitting on a gray sofa"
        />
      </section>
    </div>
  </section>
</template>

<style scoped>
.contact-us {
  min-height: 100vh;
  display: flex;
  flex-flow: row wrap;
  background: #a9a9a9;
  padding-top: 4.5rem;
  align-content: flex-start;
}

.contact-us-heading {
  font-size: 30px;
  color: #001f3f;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  width: 100%;
  margin-bottom: 2px;
  text-align: left;
  padding: 0px 2rem;
}

.contact-us-subheading {
  width: 95%;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #003366;
  text-align: left;
  padding: 0px 2rem;
}

.contact-us-form-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 2rem;
}

.contact-us-form {
  font-family: "Roboto", sans-serif;
  width: 100%;
  position: relative;
}

.contact-us-form .sending-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  background: rgb(134, 134, 135, 0.5);
  transform: translate(-50%, -50%);
  height: calc(100% - 10px);
  width: calc(100% - 20px);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-form input,
.contact-us-form textarea {
  color: rgba(var(--bs-body-color-rgb), 0.95);
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

textarea {
  resize: none;
}

.form-group {
  margin-bottom: 18px;
}

.form-group input,
.form-group textarea {
  background: #a9a9a9;
  font-size: 12px;
  text-align: start;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  box-shadow: none;
  border: none;
  border: 2px solid #ff5733;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-group.message-textarea {
  position: relative;
  margin-bottom: 22px;
}

.form-group .message-count {
  position: absolute;
  bottom: -20px;
  right: 10px;
  font-weight: bold;
  font-size: 12px;
}

.message-count.max-limit-hit {
  color: darkred;
}

.form-group label,
.form-label {
  text-align: left;
  width: 100%;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}

.form-control {
  border: none;
  border: 1px solid #2c3e50;
}

.contact-us-images {
  display: none;
}

.inquiries-errors {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.inquiry-error-text {
  font-size: 12px;
  color: darkred;
  width: 100%;
}

.successful-response,
.failed-response {
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.success-header,
.failed-header {
  width: 100%;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  margin-bottom: 3px;
}

.btn-send {
  background-color: rgb(0, 31, 66);
  margin-top: 10px;
}

.btn-send:disabled {
  background-color: rgb(0, 31, 66);
}

@media only screen and (min-width: 1010px) {
  .contact-us {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
  }

  .contact-us-heading,
  .contact-us-subheading {
    margin: 5px auto;
    width: 100%;
    text-align: center;
  }

  .contact-us-heading {
    margin-top: 1rem;
  }

  .contact-us-subheading {
    margin: 10px auto;
    text-align: center;
    max-width: 52rem;
    font-size: 16px;
  }

  .contact-us-form {
    padding: 15px 35px;
  }

  .contact-us-form,
  .contact-us-description {
    width: 70%;
    max-width: 40rem;
  }

  .contact-us-description {
    margin: 10px auto;
  }

  .contact-us-images {
    display: flex;
    width: 30%;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }

  .contact-founder-img {
    max-height: 20rem;
    max-width: 100%;
    border-radius: 5px;
  }

  .form-responses {
    width: 40%;
  }

  .failed-response,
  .successful-response {
    height: 100%;
    align-content: center;
    padding: 1rem 1.5rem;
  }
}
</style>
