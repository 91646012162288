import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import { useSeoMeta } from "@unhead/vue";
import { onBeforeMount } from "vue";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { auth } from "./main";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onBeforeMount(() => {
  useSeoMeta({
    ogType: "website",
    ogImage:
      "https://firebasestorage.googleapis.com/v0/b/csquaredmediallc.appspot.com/o/C2%20Media%20Logo_BC.png?alt=media&token=a3a9308b-cef8-4db3-a7d4-082c81e8a931",
    ogUrl: "https://c-squared-media.com",
    twitterImage:
      "https://firebasestorage.googleapis.com/v0/b/csquaredmediallc.appspot.com/o/C2%20Media%20Logo_BC.png?alt=media&token=a3a9308b-cef8-4db3-a7d4-082c81e8a931",
    twitterCard: "summary_large_image",
  });
});

onAuthStateChanged(auth, async(user) => {
  if (!user) {
    await signInAnonymously(auth)
  }
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Navbar),
    _createVNode(_component_router_view),
    _createVNode(Footer)
  ], 64))
}
}

})