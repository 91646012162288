import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/csquared-founder-4.jpg'


const _hoisted_1 = {
  class: "contact-us",
  id: "contact"
}
const _hoisted_2 = { class: "contact-us-form-container" }
const _hoisted_3 = {
  key: 0,
  class: "sending-overlay"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-group message-textarea" }
const _hoisted_9 = {
  key: 1,
  class: "inquiries-errors"
}
const _hoisted_10 = {
  key: 1,
  class: "form-responses"
}
const _hoisted_11 = {
  key: 0,
  class: "successful-response"
}
const _hoisted_12 = {
  key: 1,
  class: "failed-response"
}

import { uploadInquiry } from "@/composables/useFirebase";
import { ref } from "vue";
import validator from "validator";
import { Head } from "@unhead/vue/components";
import { Inquiry } from "../types";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUsView',
  setup(__props) {

const inquiry = ref<Inquiry>({
  firstName: "",
  lastName: "",
  message: "",
  email: "",
  phoneNumber: "",
});

const showMessageError = ref(false);
const showSuccessMessageSent = ref(false);
const messageErrors = ref<string[]>([]);
const showMessageErrors = ref(false);
const sendingInquiry = ref(false);

async function onCaptchaInquirySubmit(token: string) {
  if (sendingInquiry.value) return;

  sendingInquiry.value = true;

  const success = await uploadInquiry(inquiry.value, token);

  if (success) {
    showSuccessMessageSent.value = true;

    const container = document.getElementById("contact");

    if (container) {
      container.scrollIntoView({ behavior: "smooth" });
    }
  } else {
    showMessageError.value = true;
  }

  sendingInquiry.value = false;
}

function toggleCaptcha() {
  showMessageErrors.value = false;
  messageErrors.value = [];

  const errors = validateInquiry(inquiry.value);

  if (errors.length === 0) {
    const grecaptcha = window.grecaptcha;

    if (grecaptcha) {
      grecaptcha.render("grecaptcha-container-msg", {
        sitekey: `${process.env.VUE_APP_RECAPTCHA_KEY}`,
        callback: onCaptchaInquirySubmit,
      });
    } else {
      showMessageError.value = true;
    }
  } else {
    showMessageErrors.value = true;
    messageErrors.value = errors;
  }
}

function preventNonNumeric(event: KeyboardEvent) {
  const allowedCharacters = /[0-9]/;
  if (
    !allowedCharacters.test(event.key) ||
    inquiry.value.phoneNumber.length > 11
  ) {
    event.preventDefault();
  }
}

function formatPhoneNumber() {
  const phoneNumber = inquiry.value.phoneNumber.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  const formattedPhoneNumber = phoneNumber.replace(
    /(\d{3})(\d{3})(\d{4})/,
    "$1-$2-$3"
  ); // Format as XXX-XXX-XXXX
  inquiry.value.phoneNumber = formattedPhoneNumber;
}

function validateInquiry(inquiry: Inquiry) {
  const errors: string[] = [];

  if (validator.isEmpty(inquiry.firstName)) {
    errors.push("First Name is required.");
  } else if (
    !validator.isAlpha(inquiry.firstName, "en-US", { ignore: "-' " })
  ) {
    errors.push(
      "First Name can only contain alphabetic characters, spaces, dashes, and apostrophes."
    );
  }

  if (validator.isEmpty(inquiry.lastName)) {
    errors.push("Last Name is required.");
  } else if (!validator.isAlpha(inquiry.lastName, "en-US", { ignore: "-' " })) {
    errors.push(
      "Last Name can only contain alphabetic characters, spaces, dashes, and apostrophes."
    );
  }

  if (validator.isEmpty(inquiry.email)) {
    errors.push("Email is required.");
  } else if (!validator.isEmail(inquiry.email)) {
    errors.push("Invalid email format.");
  }

  if (validator.isEmpty(inquiry.message)) {
    errors.push("Message is required.");
  } else if (inquiry.message.length > 500) {
    errors.push(
      "Message character limit exceeded. Maximum 500 characters allowed."
    );
  }

  if (
    !validator.isEmpty(inquiry.phoneNumber) &&
    !validator.isMobilePhone(inquiry.phoneNumber, "any", { strictMode: false })
  ) {
    errors.push("Invalid phone number format. Required format: XXX-XXX-XXXX");
  }

  return errors;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Head), null, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [
        _createElementVNode("title", null, "Contact Us | C Squared Media, LLC", -1),
        _createElementVNode("meta", {
          name: "description",
          content: "Contact us today to book a consultation or inquire about our services."
        }, null, -1),
        _createElementVNode("meta", {
          property: "og:title",
          content: "Contact Us | C Squared Media, LLC"
        }, null, -1),
        _createElementVNode("meta", {
          property: "og:description",
          content: "Contact us today to book a consultation or inquire about our services."
        }, null, -1),
        _createElementVNode("meta", {
          name: "twitter:title",
          content: "Contact Us | C Squared Media, LLC"
        }, null, -1),
        _createElementVNode("meta", {
          name: "twitter:description",
          content: "Contact us today to book a consultation or inquire about our services."
        }, null, -1)
      ])),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      _cache[19] || (_cache[19] = _createElementVNode("span", { class: "contact-us-heading" }, "Contact Us", -1)),
      _cache[20] || (_cache[20] = _createElementVNode("span", { class: "contact-us-subheading" }, "Get in touch with us using our convenient contact form. Whether you have questions, inquiries, or want to discuss your project, we're here to listen and assist you. Reach out today, and let's start the conversation. ", -1)),
      _createElementVNode("div", _hoisted_2, [
        (!showSuccessMessageSent.value && !showMessageError.value)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              class: "contact-us-form",
              onSubmit: _withModifiers(toggleCaptcha, ["prevent"]),
              action: "?",
              method: "POST"
            }, [
              (sendingInquiry.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[8] || (_cache[8] = [
                    _createElementVNode("div", {
                      class: "spinner-border",
                      role: "status"
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _cache[9] || (_cache[9] = _createElementVNode("label", { for: "firstName" }, "First Name", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inquiry.value.firstName) = $event)),
                  type: "text",
                  class: "form-control",
                  id: "firstName",
                  required: "",
                  autocomplete: "given-name"
                }, null, 512), [
                  [_vModelText, inquiry.value.firstName]
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _cache[10] || (_cache[10] = _createElementVNode("label", { for: "lastName" }, "Last Name", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inquiry.value.lastName) = $event)),
                  type: "text",
                  class: "form-control",
                  id: "lastName",
                  required: "",
                  autocomplete: "family-name"
                }, null, 512), [
                  [_vModelText, inquiry.value.lastName]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[11] || (_cache[11] = _createElementVNode("label", { for: "email" }, "Email", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((inquiry.value.email) = $event)),
                  type: "email",
                  class: "form-control",
                  id: "email",
                  autocomplete: "email",
                  required: ""
                }, null, 512), [
                  [_vModelText, inquiry.value.email]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[12] || (_cache[12] = _createElementVNode("label", { for: "phoneNumber" }, "Phone Number", -1)),
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((inquiry.value.phoneNumber) = $event)),
                  onInput: _cache[4] || (_cache[4] = ($event: any) => (formatPhoneNumber())),
                  type: "tel",
                  class: "form-control",
                  id: "phoneNumber",
                  pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
                  onKeypress: _cache[5] || (_cache[5] = ($event: any) => (preventNonNumeric($event))),
                  title: "XXX-XXX-XXXX",
                  autocomplete: "tel-national",
                  required: ""
                }, null, 544), [
                  [_vModelText, inquiry.value.phoneNumber]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[13] || (_cache[13] = _createElementVNode("label", { for: "message" }, "Message ", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((inquiry.value.message) = $event)),
                  class: "form-control",
                  id: "message",
                  style: {"height":"6rem"},
                  pattern: "[a-zA-Z0-9\\s',.\\-]*",
                  maxlength: "500",
                  rows: "5",
                  required: ""
                }, null, 512), [
                  [_vModelText, inquiry.value.message]
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(`message-count ${
              inquiry.value.message.length > 500 ? 'max-limit-hit' : ''
            }`)
                }, " (" + _toDisplayString(inquiry.value.message.length) + "/500) ", 3)
              ]),
              (showMessageErrors.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(messageErrors.value, (messageError, errorIndex) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: errorIndex,
                        class: "inquiry-error-text"
                      }, _toDisplayString(messageError), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _cache[14] || (_cache[14] = _createElementVNode("div", {
                class: "google-recaptcha-container",
                id: "grecaptcha-container-msg"
              }, null, -1)),
              _cache[15] || (_cache[15] = _createElementVNode("button", {
                type: "submit",
                class: "btn btn-secondary btn-send"
              }, "Send", -1))
            ], 32))
          : _createCommentVNode("", true),
        (showSuccessMessageSent.value || showMessageError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (showSuccessMessageSent.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[16] || (_cache[16] = [
                    _createElementVNode("span", { class: "success-header" }, "Thank You!", -1),
                    _createElementVNode("span", { class: "success-description" }, "We look forward to following up with you.", -1)
                  ])))
                : _createCommentVNode("", true),
              (showMessageError.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[17] || (_cache[17] = [
                    _createElementVNode("span", { class: "failed-header" }, "Oops! Something went wrong.", -1),
                    _createElementVNode("span", { class: "failed-description" }, "We encountered an issue while processing your consultation request. Please contact us directly using the listed contact methods below for assistance.", -1)
                  ])))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _cache[18] || (_cache[18] = _createElementVNode("section", { class: "contact-us-images" }, [
          _createElementVNode("img", {
            class: "contact-founder-img",
            src: _imports_0,
            alt: "woman sitting on a gray sofa"
          })
        ], -1))
      ])
    ])
  ], 64))
}
}

})